<template>
	<div>
			<div :key="i" v-for="(folder,i) in realValue">
					<v-row
							:style="{marginLeft: folder.profondeur * 10 + 'px !important'}"
							no-gutters
							align="center"
							@ondragstart="(e) => {$tools.log('drag');e.preventDefault()}"
							@mouseenter="$set(hover, i, true)"
							@mouseleave="$set(hover, i, false)"
							@click="$emit('changeCurrentFolder', folder)"
							:class="hover[i] ? folder.name === current.name ? 'cell3' : 'cell': folder.name === current.name ? 'cell3' : ''"
							class="pa-1 rounded-lg transition-cubic item-sub-left"
							
					>
						
						<v-col cols="auto" class="cursor-pointer " @click.stop="folder.folders.length > 0 ? expanded = !expanded: ''">
							<v-icon
									:class="expanded ? '':'transition-90-rotate'"
									size="20"
									v-if="folder.folders.length > 0"
							>
								mdi-chevron-down
							</v-icon>
							<v-icon size="20" color="cell2" v-else>mdi-chevron-right</v-icon>
						</v-col>
						<v-col cols="auto" >
							<v-icon size="18">mdi-folder</v-icon>
						</v-col>
						<v-col>
							<div class="ml-2 mt-1 paragraph text-truncate" :title="folder.path ==='/' || folder.path ==='//' ? '/' : folder.name">{{ folder.path ==='/' || folder.path ==='//' ? '/' : folder.name }}</div>
						</v-col>
						
					</v-row>
				<!--:class="current.name === folder.name && current.path === folder.path ? 'primary&#45;&#45;text' : ''"-->
				<!--:color="current.name === folder.name && current.path === folder.path ? 'primary' : ''"-->
				
				<v-expand-transition >
					<div v-show="folder.folders.length > 0 ? expanded : true" :id="folder.path" >
							<draggable ghost-class="ghost" :group="{ name: 'files', pull : false, put: false }" :sort="false" disabled  align="end" >
								<!-- <div @mouseenter="$set(hover, i, true)" @mouseleave="$set(hover, i, false)" :class="hover[i] ? 'red' : ''" :style="hover[i] ? {height: '50px'}: {}"></div> -->
								<NestedTest v-if="folder.folders.length > 0" :list="folder.folders" @changeCurrentFolder="(e) => $emit('changeCurrentFolder', e)" :current="current" />
								
							</draggable>
					</div>
				</v-expand-transition>
			</div>
	</div>
</template>

<script>
	export default {
		name: "NestedTest",
		props: {
			value: {
				required: false,
				type: Array,
				default: null
			},
			list: {
				required: false,
				type: Array,
				default: null
			},
			current: {}		},
		components: {
			draggable: () => import('vuedraggable')
		},
		data(){
			return {
				expanded: true,
				hover : []
			}
		},
		computed: {
			realValue() {
				return this.value ? this.value : this.list;
			}
		},
		watch: {
			current(val){
				if(val.name === this.realValue[0].name && val.name !== ''){
					this.expanded = true
				}

				if(val.parent !== null && val.parent.name === this.realValue[0].name){
					this.expanded = true
				}
			}
		},
		created(){
			let val = this.value ? this.value[0].profondeur : this.list[0].profondeur
			this.expanded = val < 1
		}
	};
</script>
<style scoped>


</style>